import Axios from 'axios'
import React, { Component } from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'

export default class CheckoutSuccess extends Component {
    state={}
    componentDidMount() {
        const {trackInfo} = this.props;
        const {metadata} = trackInfo
        const balanceIncrease = parseInt(metadata.price) * .8;
        // maybe try as a put later
        axios.post(`/api/update/${trackInfo.filename}`, {producerBalanceIncrement: balanceIncrease});
        axios.post(`/api/update/${trackInfo.filename}`, {producerBalanceIncrement: balanceIncrease});
        // axios.get(`http://localhost:3001/download/${trackInfo.filename}`)
        const anchor = document.querySelector('#ref');
        anchor.click()
    }
    render() {
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "12%",
                fontSize: "20px",
                color: "white",
            }}>
                <div>Congrats on your fresh squeezed track purchase!</div>
                <div>Your download started automatically..</div>
                {/* relative paths for the automatic download may not work here */}
                <a style={{margin: "10px 5px"}} id="ref" href={`https://app.lemonadebeats.com/api/download/${this.props.trackInfo.filename}`}></a>
                {/* <a id="home" href={`http://localhost:3001/api/download/${this.props.trackInfo.filename}`}>DOWNLOAD</a> */}
                <Link to="/shop">Continue Shopping</Link>
            </div>
        )
    }
}

