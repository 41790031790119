import React, { useState, useEffect } from 'react'
import './PlayerHeader.css'
import { Link } from 'react-router-dom'
import axios from 'axios'
// import { useState } from 'react';

// @TODO iterate over producerBalance on each track and display the sum in this header
export default function PlayerHeader(props) {
    const { trackInfo, trackQty, user } = props;
    const hasTracks = trackQty > 0
    const stripeAcctId = hasTracks ? trackInfo[0].metadata.stripeAcctId : ""
    // console.log(stripeAcctId)
    const hasStripe = stripeAcctId ? true : false
    
    const [acctLink, setAcctLink] = useState("")
    const [acctId, setAcctId] = useState("")
    const [moneyInTransit, setMoneyInTransit] = useState(false)

    let balance = 0
    let balanceAsCents = 0
    // we can only calculate balance if user already has tracks to map
    if ( hasTracks ) {
        console.log("tracks already present")
        const balanceArray = hasTracks && trackInfo.map(track => {
            const {producerBalance} = track.metadata;
            const numericalBalance = parseFloat(producerBalance).toFixed(2);
            if ( !producerBalance || producerBalance == 'undefined' || isNaN(producerBalance)  ) {
                return;
            }
            return parseFloat(numericalBalance);
        })
        balanceArray.map((item) => {
            if ( typeof(item) === 'number' ) {
                balance = balance + item
            }
        })
        balanceAsCents = parseInt(balance*100)
        console.log(`balance is ${balanceAsCents}`)

    } 

    // useEffect(() => {
        // if ( hasStripe !== "" ) {
        //     axios.post('/api/createAccountLink/', {id: stripeAcctId})
        //     .then(data => {
        //         console.log(data.data.accountLink)
        //         setAcctLink(data.data.accountLink)
        //     })
        //     .catch(err => console.log(err))
        //     // const stripeLink = link && link.accountLink
        //     // console.log(link)
        // }
    // })
    
    const handleOnboardClick = async (e) => {
        console.log("click works")
        const {data} = await axios.post('/api/connect/')
        console.log(data)
        setAcctLink(data.accountLink)
        setAcctId(data.accountId)
        //could iterate over tracks and add stripeAcctId field to each for now - simplify later with a User model
        if(trackInfo)
        {

        const trackMap = trackInfo.map(async (item) => {
            // update item.metadata.stripeAcctId with data.accountId
            const {info} = await axios.post(`/api/updateStripeInfo/${item.filename}`, {acctId: data.accountId})
            axios.post('/api/createAccountLink/', {id: stripeAcctId})
            .then(data => {
                console.log(data.data.accountLink)
                setAcctLink(data.data.accountLink)
            })
            .catch(err => console.log(err))
            console.log(info)
        })
    }

        // remove onboarding link
        // if tracks have stripeAcctId, don't show onboarding
    }
    ///////////
    const handleWithdrawClick = async (e) => {
        e.preventDefault();
        console.log("withdraw click")
        
        const {data} = await axios.post('/api/transfer/', {acctId: stripeAcctId, balance: balanceAsCents})
        console.log(data)
        setMoneyInTransit(true)
        // then need to set balance to 0 on each track
        // trackInfo.map(item => {
        //     //
        // })
    }
    /////////////
    // iterate over trackInfo
    // pull value from producerBalance from each
    // keep a sum => .reduce() method here??
    return (
        <div className="playerHeader-wrapper">
            <div className="playerHeader-title">Hi, {user && user.nickname}!</div>            
            <div className="playerHeader-infoContainer">
                <div className="totalSongs">{`Currently selling ${(trackQty && trackQty.length !== 0) ? trackQty : 0} tracks`}</div>
                { !moneyInTransit && <div className="balance">{`Balance: ${balance ? '$'+balance : "Loading.."}`}</div> }
                { (!hasStripe || trackInfo.length === 0) ? <a href="javascript:void(0)" onClick={handleOnboardClick}>Create Link for Stripe Account</a> : "" }
                {/* <a href="javascript:void(0)" onClick={handleOnboardClick}>Create Link for Stripe Account</a> */}
            </div>            
            <div className="playerHeader-playAllButton">
                <Link to="/shop">EXPLORE</Link>
                { hasStripe && !moneyInTransit  ? (
                    <a href="javascript:void(0)" onClick={handleWithdrawClick}>{`WITHDRAW ${balance ? '$'+balance : "BALANCE"} TO STRIPE`}</a>
                ) : 'Balance in transit to your Stripe Account!'}
                { acctLink && <a href={acctLink}>GO TO STRIPE</a> }
            </div>
        </div>
    )
}
// https://connect.stripe.com/express/onboarding/aAarj7hqDQ1T#/