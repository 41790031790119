import React from 'react';
import './App.css';
import WaveForm from './components/WaveForm.js';
import WaveFormTemp from './components/WaveFormTemp.js';
import PlayerHeader from './components/PlayerHeader.js';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import lemonadeLogo from './assets/lbeats_logo.png';
import axios from 'axios';
import BasicUploader from './components/BasicUploader';
import TitleModal from './components/TitleModal';
import { TracksContext } from './context/TracksContext';
import WaveSurfer from 'wavesurfer.js';
import Moment from 'react-moment';
import { useAuth0 } from '@auth0/auth0-react'
import dotenv from 'dotenv';
import MyPlayer from './components/MyPlayer';
import {Link} from 'react-router-dom'
import Nav from './components/Nav'

// console.log(res.filter(item => {
//   return item.metadata.artist == "Dont"
// }));
const App = () => {
  const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();
  // Similar to componentDidMount and componentDidUpdate:
  // useEffect(() => {
  //   document.addEventListener('play', function(e){
  //       var audios = document.getElementsByTagName('audio');
  //       for(var i = 0, len = audios.length; i < len;i++){
  //           if(audios[i] != e.target){
  //               audios[i].pause();
  //           }
  //       }
  //   }, true);
  // });
  return (
    <TracksContext.Consumer>
    {(value) => {
      const userID = user && user.sub;
      console.log(process.env.REACT_APP_AUTH0_DOMAIN);
      const trackInfo = value.trackInfo && value.trackInfo;
      //set this up to filter user's tracks and return those only
      const userTracks = trackInfo && trackInfo.filter(item => {
          return item.metadata.userID == userID
      });
      return (
      <div className="App">
        <div className="container">
          {/* <a className="backHomeLink" href="http://www.lemonadebeats.com"> &lt;&lt; HOME</a> */}
          <Nav />
          {/* <a className="backHomeLink" href="http://www.lemonadebeats.com"><img className="logo" src={lemonadeLogo} alt="logo" /></a> */}
          {/* <img className="logo" src={lemonadeLogo} alt="logo" /> */}
          {isAuthenticated ? (
          <button className="cta yellow" onClick={() => logout()}>Log Out</button>
          ) : (
          <>
          <div className="copy">Sign Up or Log In to start uploading your tracks now!</div>
          {/* <div styles={{display: "flex"}}> */}
          <button className="cta yellow" onClick={() => loginWithRedirect()}>Start Uploading Now</button>
          <Link to='/shop'><button className="cta yellow">Shop as Guest</button></Link>
          {/* <button className="cta yellow" onClick={() => loginWithRedirect()}>Log In</button> */}
          {/* </div> */}
          </>
          )}
          {/* <MyPlayer /> */}
          {isAuthenticated && (
          <div className="player-container" >
            <div className="headerContainer">
              <PlayerHeader trackInfo={userTracks} trackQty={userTracks && userTracks.length} user={user} />
              <TitleModal addATrackToContext={value.setNewTrack} userID={user.sub} nickname={user.nickname} picture={user.picture} />
            </div>
            <div className="trackHeader">
              <span>Track</span>
              <span>BPM</span>
              <span>Duration</span>
              <span>Edit</span>
            </div>
            {/* {value.trackInfo && value.trackInfo.map((track, idx) => (
              <WaveForm track={track} thisKey={idx} />
            ))} */}
            {userTracks && userTracks.map((track, idx) => (
              <WaveFormTemp editControls={true} track={track} thisKey={idx} />
            ))}
          </div>
          )}
        </div>
      </div>
      )
    }
    }
    </TracksContext.Consumer> 
  );
}

export default App;
