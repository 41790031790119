import React, { Component } from 'react'

export class ModalInputForm extends Component {
    state = {
        title: '',
        artist: ''
    }
    handleChange = input => e => {
        this.setState({ [input]: e.target.value })
    }
    render() {
        const { title, artist } = this.state;
        const values = { title, artist }
        return (
            <div>
                <form>
                    <label>
                        Track Title:
                        <input type="text" name="name" />
                    </label>
                    <label>
                        Artist:
                        <input type="text" name="artist" />
                    </label>
                    <input type="submit" value="Submit" />
                </form>
            </div>
        )
    }
}

export default ModalInputForm
