import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Modal, { ModalProvider } from 'styled-react-modal'
import ModalInputForm from './ModalInputForm'
import BasicUploader from './BasicUploader'
import './TitleModal.css';

const StyledModal = Modal.styled`
  width: 20rem;
  height: 75%;
  height: auto;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  flex-direction: column;
  text-align: center;
`

class FancyModalButton extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: false,
      someInput: ''
    }

    this.toggleModal = this.toggleModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  toggleModal (e) {
    // this.setState((prevState) => ({ isOpen: !prevState.isOpen }))
  }
  openModal (e) {
    this.setState({isOpen: true})
  }
  closeModal (e) {
    console.log('close modal')
    this.setState({isOpen: false})
  }

  render () {
    const {addATrackToContext, userID, nickname, picture} = this.props;
    const user = this.props.userID;
    return (
      <div className="upload-container" onClick={this.openModal}>
        <span className="upload-icon">+</span>
        <span className="upload-description">Add a track..</span>
        <StyledModal
          isOpen={this.state.isOpen}
          onEscapeKeydown={this.closeModal}>
          <h2 className="modalTitle">Add A Track</h2>
          <span className="modalCopy">Add your track information and file below. After you submit, it may take a few minutes to process on our server..</span>
          <BasicUploader addTrack={addATrackToContext} closeModal={this.closeModal} userID={userID} nickname={nickname} picture={picture} />
          {/* <button onClick={this.toggleModal}>Cancel</button> */}

          {/* <button onClick={this.toggleModal}>Cancel</button> */}
        </StyledModal>
      </div>
    )
  }
}

export default class TitleModal extends Component {
  
  render () {
    const {userID, nickname, picture} = this.props
    return (
      <ModalProvider>
        <span></span>
        <FancyModalButton userID={userID} nickname={nickname} picture={picture}  />
      </ModalProvider>
    )
  }
}