import React, { Component } from 'react'
import { FaPlay, FaPause } from 'react-icons/fa';
import { guess } from 'web-audio-beat-detector';
import {
  WaveformContianer,
  Wave,
  PlayButton,
  Price,
  ProfileLink,
  TrackName,
  TrackArtist,
  TrackBPM,
  TrackDuration,
  TrackInfoContainer,
} from './WaveFormStyled';
import Ranger from './Ranger'

import EditModal from './EditModal'
import Moment from 'react-moment';
import moment from 'moment';
import Checkout from './shop/Checkout'
import { NavLink, Link } from 'react-router-dom'
import { trackLifecycle } from 'aws-amplify-react';

export default class WaveForm extends Component {
  state = {
    playing: false,
    bpm: '...',
    duration: '--',
    loadingWave: true,
    trackPercentage: 0
  };


  componentDidMount() {


    //listener set up on mount that will ensure only one track plays at a time
    // if (window.getSelection().rangeCount >= 1) {var r = window.getSelection().getRangeAt(0);}
    document.addEventListener('play', function (e) {
      var audios = document.getElementsByTagName('audio');
      for (var i = 0, len = audios.length; i < len; i++) {
        if (audios[i] != e.target) {
          audios[i].pause();

        }
      }
    }, true);

    const thisKey = this.props.thisKey;
    const trackInfo = this.props.track;
    var audio = new Audio(trackInfo)

    const track = document.querySelector(`#track${thisKey}`);
    // console.log("this key = " + thisKey);
    // console.log(`trackInfo:`, trackInfo);
    // console.log(`track= ${track}`);
    // console.log(audio);
    this.setState({ duration: [audio.duration] })
    var duration = '';
    track.addEventListener('durationchange', function (e) {
      if (track.duration != Infinity) {
        duration = track.duration
        setIt(duration);
        // console.log(`duration= ${duration}`);
      };
    }, false);

    const setIt = (duration) => {
      this.setState({ duration: duration });
    }

    //update the time
    track.addEventListener('timeupdate', (e) => {
      // console.log(e.target.duration)
    })


    // this.waveform = WaveSurfer.create({
    //     barWidth: 2,
    //     cursorWidth: 1,
    //     maxCanvasWidth: 100,
    //     container: `#waveform${thisKey}`,
    //     // backend: 'WebAudio',
    //     backend: 'MediaElement',
    //     height: 30,
    //     progressColor: '#00D5FF',
    //     normalize: true,
    //     forceDecode: false,
    //     responsive: true,
    //     waveColor: '#FFE600',
    //     cursorColor: 'transparent',
    // });
    // this.waveform.on('waveform-ready', () => {
    //   this.setState({loadingWave: false})
    // })

    // this.waveform.load(track)
    // this.setState({loadingWave: false})
    // setTimeout(() => {
    //   this.setState({loadingWave: false})
    // }, 15000)
    //try to get audiocontext to resume
    // this.waveform.backend.getAudioContext().resume();


  };

  componentDidUpdate(prevProps, prevState) {

    // if (prevState.playing !== this.state.playing) {
    //   console.log('toggled');
    //   this.setState({ playing: !this.state.playing });
    // }
  }

  setIt = (duration) => {
    this.setState({ duration: duration });
  }

  handleDurationChange = () => {
    const thisKey = this.props.thisKey;
    const track = document.querySelector(`#track${thisKey}`);

    if (track.duration != Infinity) {
      var duration = track.duration
      this.setIt(duration);
      // console.log(`duration= ${duration}`);
    };
  }
  // make sure play button is in initial state at the end of a fully played track
  handleEndOfTrack = () => {
    this.setState({ playing: false })
  }

  // TODO - grab bpm upon upload & store

  // handler for track actually pausing event
  handlePause = () => {
    console.log("pausing");
    this.setState({ playing: false });
  }
  // handler for track actually playing event
  handlePlaying = () => {

    const thisKey = this.props.thisKey;
    const track = document.querySelector(`#track${thisKey}`);

    console.log("playing");
    this.setState({ playing: true });
    // console.log(track.currentTime)
  }

  handleEditClick = () => {

  }

  // handler for clicking the play button on a track
  handlePlayButton = async (e) => {

    const thisKey = this.props.thisKey;
    const track = document.querySelector(`#track${thisKey}`);


    // Change the src attribute of the image
    track.setAttribute( 'src', track.getAttribute('data-src') );
  

    // if not playing , then play
    if (this.state.playing == false) {
      this.setState({
        playing: true
      })
      await track.play();
      // this.setState({
      //   playing: false
      // })

    } else {
      track.pause()
      this.setState({
        playing: false
      })
    }
    // console.log(track)
    //   guess(this.waveform.backend.buffer)
    //     .then(({bpm}) => {
    //         console.log(`BPM: ${bpm}`);
    //         this.setState({
    //           bpm: bpm,
    //         })
    //     })
    //     .catch(err => console.log(`error: ${err}`))

    //   const duration = this.waveform.getDuration();
    //   // moment(duration, 's').format('mm:ss');
    //   console.log(duration);
    //   this.setState({ duration: duration });
    //   track.play(); 
  };

  render() {
    const { track, thisKey, editControls } = this.props;
    const { duration, loadingWave } = this.state;
    const fileName = track.filename;
    // console.log(fileName);

    return (
      <WaveformContianer>
        <PlayButton onClick={this.handlePlayButton}>
          {this.state.playing ? <FaPause /> : <FaPlay />}
        </PlayButton>
        {/* <Wave id={`waveform${thisKey}`} /> */}
        <Ranger />
        {track.metadata ? (
          <TrackInfoContainer>
            <TrackName>{track.metadata.title}</TrackName>

            <NavLink
              id="profile-link"
              style={{ textDecoration: "none", color: "#00D5FF", }}
              to={{
                pathname: `/producer-profile/`,
                state: {
                  trackInfo: track,
                  userID: track.metadata.userID,
                }
              }}>
              <ProfileLink>
                <TrackArtist>{`By: ${track.metadata.artist}`}</TrackArtist>
              </ProfileLink>
            </NavLink>


            {/* <TrackArtist>{`By: ${track.metadata.artist}`}</TrackArtist> */}
          </TrackInfoContainer>
        ) : (
          <TrackInfoContainer>
            <TrackName>Placeholder - will Delete</TrackName>
            <TrackArtist>Artist Placeholder</TrackArtist>
          </TrackInfoContainer>
        )}
        <TrackBPM>{this.state.bpm}</TrackBPM>
        <TrackDuration>{duration !== 0 && moment.duration(duration, "seconds").format('mm:ss')}</TrackDuration>
        <Price style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
          {!editControls ? (
            <Link style={{ textDecoration: "none", fontSize: "20px", }} to={{
              pathname: `/checkout/${track.filename && track.filename}`,
              state: {
                trackInfo: track
              }
            }}>${(track.metadata && track.metadata.price) ? track.metadata.price : 'FREE'}</Link>
          ) : (
            <EditModal track={track} />
          )}
        </Price>
        {/* <TrackBPM><Checkout href="javascript:void(0)">{(track.metadata && track.metadata.price) ? track.metadata.price : 'FREE'}</Checkout></TrackBPM> */}
        {/* TODO: Change to refs instead of using this audio element id */}
        <audio id={`track${thisKey}`} onPlaying={this.handlePlaying} onEnded={this.handleEndOfTrack} onPause={this.handlePause} onDurationChange={this.handleDurationChange} data-src={`/api/track/${fileName}`} />
      </WaveformContianer>
    );
  }
}
