import React, { Component } from 'react'
import WaveSurfer from 'wavesurfer.js';
import { FaPlay, FaPause } from 'react-icons/fa';
import { guess } from 'web-audio-beat-detector';
import {WaveformContianer,
        Wave,
        PlayButton,
        Price,
        ProfileLink,
        TrackName,
        TrackArtist,
        TrackBPM,
        TrackDuration,
        TrackInfoContainer, } from './WaveFormStyled';

import EditModal from './EditModal'
import Moment from 'react-moment';
import moment from 'moment';
import Checkout from './shop/Checkout'
import {NavLink, Link} from 'react-router-dom'

export default class WaveForm extends Component {  
    state = {
      playing: false,
      bpm: '...',
      duration: 0,
      loadingWave: true,
    };


    componentDidMount() {
        //listener set up on mount that will ensure only one track plays at a time
        // if (window.getSelection().rangeCount >= 1) {var r = window.getSelection().getRangeAt(0);}
        document.addEventListener('play', function(e){
            var audios = document.getElementsByTagName('audio');
            for(var i = 0, len = audios.length; i < len;i++){
                if(audios[i] != e.target){
                    audios[i].pause();
                   
                }
            }
        }, true);

        const thisKey = this.props.thisKey;
        const trackInfo = this.props.track;
        const track = document.querySelector(`#track${thisKey}`);
        // console.log("this key = " + thisKey);
        // console.log(`trackInfo:`, trackInfo);
        // console.log(`track= ${track}`);
        var duration = '';
        track.addEventListener('durationchange', function(e){
          if (track.duration!=Infinity) {
            duration = track.duration
            setIt(duration);
            // console.log(`duration= ${duration}`);
          };
        }, false); 
        
        const setIt = (duration) => {
          this.setState({ duration: duration });
        }


        // var getDuration = function (track.src, next) {
        //   var _player = new Audio(url);
        //   _player.addEventListener("durationchange", function (e) {
        //       if (this.duration!=Infinity) {
        //          var duration = this.duration
        //          _player.remove();
        //          next(duration);
        //       };
        //   }, false);      
        //   _player.load();
        //   _player.currentTime = 24*60*60; //fake big time
        //   _player.volume = 0;
        //   _player.play();
        //   //waiting...
        // };



      
      // getDuration ('/path/to/audio/file', function (duration) {
      //     console.log(duration);
      // });
      // if (this.waveform) {
      //   this.waveform.destroy();
      // }
        this.waveform = WaveSurfer.create({
            barWidth: 2,
            cursorWidth: 1,
            maxCanvasWidth: 100,
            container: `#waveform${thisKey}`,
            // backend: 'WebAudio',
            backend: 'MediaElement',
            height: 30,
            progressColor: '#00D5FF',
            normalize: true,
            forceDecode: false,
            responsive: true,
            waveColor: '#FFE600',
            cursorColor: 'transparent',
        });
        this.waveform.on('waveform-ready', () => {
          this.setState({loadingWave: false})
        })

        // this.waveform.load(`http://localhost:3001/track/${trackInfo.filename}`);
          // window.WaveSurferAudioContext.resume();
        this.waveform.load(track)
      // this.setState({loadingWave: false})
      // setTimeout(() => {
      //   this.setState({loadingWave: false})
      // }, 15000)
        //try to get audiocontext to resume
        // this.waveform.backend.getAudioContext().resume();


    };

    componentDidUpdate(prevProps, prevState) {

      // if (prevState.playing !== this.state.playing) {
      //   console.log('toggled');
      //   this.setState({ playing: !this.state.playing });
      // }
    }

    setIt = (duration) => {
      this.setState({ duration: duration });
    }

    handleDurationChange = () => {
      const thisKey = this.props.thisKey;
      const track = document.querySelector(`#track${thisKey}`);

      if (track.duration!=Infinity) {
        var duration = track.duration
        this.setIt(duration);
        // console.log(`duration= ${duration}`);
      };
    }
    // make sure play button is in initial state at the end of a fully played track
    handleEndOfTrack = () => {
      this.setState({playing: false})
    }

    // TODO - grab bpm upon upload & store

    // handler for track actually pausing event
    handlePause = () => {
      console.log("pausing");
      this.setState({ playing: false });
    }
    // handler for track actually playing event
    handlePlaying = () => {
      console.log("playing");
      this.setState({ playing: true });
    }

    handleEditClick = () => {

    }

    // handler for clicking the play button on a track
    handlePlayButton = () => {

      console.log(this.waveform)
      guess(this.waveform.backend.buffer)
        .then(({bpm}) => {
            // console.log(`BPM: ${bpm}`);
            this.setState({
              bpm: bpm,
            })
        })
        .catch(err => console.log(`error: ${err}`))

      const duration = this.waveform.getDuration();
      // moment(duration, 's').format('mm:ss');
      // console.log(duration);
      this.setState({ duration: duration });
      this.waveform.playPause();
    };

    render() {
        const { track, thisKey, editControls } = this.props;
        const {duration, loadingWave} = this.state;
        const fileName = track.filename;
        // console.log(fileName);

        return (
              <WaveformContianer>
                {loadingWave ? 'Loading' : (
                <PlayButton onClick={this.handlePlayButton}>
                  {this.state.playing ? <FaPause /> : <FaPlay />}
                </PlayButton>
                )}
                <Wave id={`waveform${thisKey}`} />
                {track.metadata ? (
                <TrackInfoContainer>
                  <TrackName>{track.metadata.title}</TrackName>

                  <NavLink 
                    id="profile-link"
                    style={{textDecoration: "none",color:"#00D5FF",}}
                    to={{
                      pathname: `/producer-profile/`,
                      state: {
                        trackInfo: track,
                        userID: track.metadata.userID,
                      }
                    }}>
                      <ProfileLink>
                        <TrackArtist>{`By: ${track.metadata.artist}`}</TrackArtist>
                      </ProfileLink>
                  </NavLink>


                  {/* <TrackArtist>{`By: ${track.metadata.artist}`}</TrackArtist> */}
                </TrackInfoContainer>
                ) : (
                <TrackInfoContainer>
                  <TrackName>Placeholder - will Delete</TrackName>
                  <TrackArtist>Artist Placeholder</TrackArtist>
                </TrackInfoContainer>
                )}
                <TrackBPM>{this.state.bpm}</TrackBPM>
                <TrackDuration>{duration !== 0 && moment.duration(duration, "seconds").format('mm:ss')}</TrackDuration>
                <Price style={{display: "flex",alignItems: "center", justifyContent: "center",}}>
                  {!editControls ? (
                    <Link style={{textDecoration: "none", fontSize: "20px",}} to={{
                      pathname: `/checkout/${track.filename && track.filename}`,
                      state: {
                        trackInfo: track
                      }
                      }}>${(track.metadata && track.metadata.price) ? track.metadata.price : 'FREE'}</Link>
                  ) : (
                    <EditModal track={track} />
                  )}
                </Price>
                {/* <TrackBPM><Checkout href="javascript:void(0)">{(track.metadata && track.metadata.price) ? track.metadata.price : 'FREE'}</Checkout></TrackBPM> */}
                {/* TODO: Change to refs instead of using this audio element id */}
                <audio id={`track${thisKey}`} onPlaying={this.handlePlaying} onEnded={this.handleEndOfTrack} onPause={this.handlePause} onDurationChange={this.handleDurationChange} src={`/api/track/${fileName}`} />
              </WaveformContianer>
        );
      }
}
