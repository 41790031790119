import React from 'react';
import { TextField } from '@material-ui/core/';
import { TracksContext } from '../context/TracksContext';
import GenreDropdown from './GenreDropdown';

export default class BasicUploader extends React.Component {
    constructor (props) {
      super(props)
    }
    state = {
        genre: "Hip-Hop"
    }
    // static contextType = TracksContext;

    handleSubmit = (e) => {
        e.preventDefault();
        const {title, artist, price, genre, file} = this.state
        // const {userID} = this.props
        console.log('handleSubmit', this.state);

        

        var formdata = new FormData();
        formdata.append("title", title);
        formdata.append("artist", artist);
        formdata.append("price", price);
        formdata.append("genre", genre);
        // formdata.append("producerBalance", parseInt(0));
        // formdata.append("qtySold", parseInt(0));
        formdata.append("userID", this.props.userID);
        formdata.append("nickname", this.props.nickname);
        formdata.append("picture", this.props.picture);
        formdata.append("file", file);
        
        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };
        
        fetch("/api/upload", requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log(result);
                this.props.closeModal();
                window.location.reload();
            })
            .catch(error => console.log('error', error));

    }

    handleInputChange = event => {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({
            [name]: value
        });
    };

    handleFileUpload = event => {
        const file = event.target.files[0];
        this.setState({
            file,
        })
    }

    render () {
        return (
        <TracksContext.Consumer>
            {(value) => {
                return (
            <div className="container">
                <div className="row">
                    <div className="col-md-6 m-auto">
                        <form action="/api/upload" method="POST" encType="multipart/form-data">

                            <TextField onChange={this.handleInputChange} style={{marginBottom: '10px'}} name="title" label="Track Title" variant="outlined" />
                            <TextField onChange={this.handleInputChange} style={{marginBottom: '10px'}} name="artist" label="Artist" variant="outlined" />
                            <TextField onChange={this.handleInputChange} style={{marginBottom: '10px'}} name="price" label="Price" variant="outlined" />
                            <GenreDropdown genre={this.state.genre} handler={this.handleInputChange} />
                            {/* <label>
                                Track Title:
                            <input type="text" name="title" />
                            </label>
                            <label>
                                Artist:
                            <input type="text" name="artist" />
                            </label> */}
                            <div style={{margin: '10px 0'}} className="custom-file mb-3">
                                <input onChange={this.handleFileUpload} style={{width:'200px'}} type="file" name="file" id="file" className="custome-file-input" />
                            </div>
                            <button onClick={this.handleSubmit}>Submit</button>
                            {/* <input type="submit" value="Submit" className="btn btn-primary btn-block" /> */}
                        </form>
                        {/* temp test */}
                        {/* <button onClick={this.props.closeModal}>Cancel</button> */}
                        <span>Press ESC key to Cancel</span>

                    </div>

                </div>
            </div>
                )
            }
        }
        </TracksContext.Consumer>
        )
    }
}
