import React, {useContext, useState, useEffect} from 'react'
// import lemonadeLogo from '../../assets/lemonadebeats_logo_large.webp';
import '../../App.css';
import PlayerHeader from '../PlayerHeader'
import { TracksContext } from '../../context/TracksContext';
import WaveFormTemp from '../WaveFormTemp'
import {Link} from 'react-router-dom'
import {TextField, Button} from '@material-ui/core'
import {Autocomplete} from '@material-ui/lab'
import Nav from '../Nav'

const styles = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor : '#FFE600',
    color: '#00D5FF',
    flexDirection: 'column',
    padding: '40px'
}

const Shop = () => {
    const contextValue = useContext(TracksContext);
    const tracks =  contextValue.trackInfo
    // const matchedTracks = tracks.filter(track => track.metadata.genre == "Hip-Hop")
    const [tracksToRender, setTracksToRender] = useState(tracks)
    const [qtyTracksToRender, setQtyTracksToRender] = useState(10)
    const [filterChosen, setFilterChosen] = useState(false)
    // const setTracksToRender = tracks => {
    //     _setTracksToRender(tracks);
    //     // window.location.reload();
    // }

    const handleInputSelect = async (event, value, reason) => {
        if (filterChosen === false) setFilterChosen(true)
        const clear = await setTracksToRender([])

        if (reason === "blur") console.log("blur")
        if ( value === "" || reason === "clear" ) {
            console.log("no search")
        }
        // value = artist
        const {trackInfo} = contextValue
        // match artist to any tracks with that meta
        const matchedArtists = trackInfo.filter(track => track.metadata.artist === value)
        // update state to store those tracks (rendered from state, where initial state should be sponsored tracks))
        setTracksToRender(matchedArtists)
        // console.log(matchedArtists)
    }
    const clearTracks = () => {
        setTracksToRender([])
        setQtyTracksToRender(10)
    }
    const handleGenreInputSelect = (event, value, reason) => {
        if (filterChosen === false) setFilterChosen(true)

        // clear out trackToRender
        clearTracks()
        const {trackInfo} = contextValue
        const matchedTracks = trackInfo.filter(track => track.metadata.genre === value)
        setTracksToRender(matchedTracks)
        console.log('trying to genre here')

    }

    const handleLoadMore = () => {
        console.log("load more tracks..")
        const {trackInfo} = contextValue
        // use setTracksToRender to add 10 more tracks
        setQtyTracksToRender(qtyTracksToRender + 10)
        // const matchedTracks = tracks && tracks.slice(0, qtyTracksToRender)
        const matchedTracks = trackInfo && trackInfo.slice(0, qtyTracksToRender)
        // console.log(qtyTracksToRender)
        // console.log(matchedTracks)
        setTracksToRender(matchedTracks)
    }
    useEffect(() => {
        // setTracksToRender(contextValue.trackInfo)

        const {trackInfo} = contextValue
        // matchedTracks are first ten of all tracks, store in local state
        const matchedTracks = trackInfo && trackInfo.slice(0, 10)
        // then make a load more button in render()
        // then onLoadMore => grab next ten adn append to state
        // const matchedTracks = trackInfo && trackInfo.filter(track => track.metadata.genre == "Hip-Hop")
        setTracksToRender(matchedTracks)
        console.log('trying to genre here')
        setQtyTracksToRender(qtyTracksToRender + 10)
    }, [tracks])
    return (
        <TracksContext.Consumer>
        {(value) => {
            const trackInfo = value.trackInfo && value.trackInfo;
            const allArtists = trackInfo && trackInfo.map(track => track.metadata.artist )
            const options = allArtists && allArtists.filter((current, i) => allArtists.indexOf(current) === i)
            const genreOptions = ["Hip-Hop", "R&B", "Dancehall", "Trap", "New School", "Rap", "Smooth", "Club", "Pop", "Beats with Hooks"]
            // const matchedTracks = trackInfo.filter(track => track.metadata.genre === "Hip-Hop")
            // setTracksToRender(matchedTracks)

            // const newObj = options && Object.assign(options, genreOptions )
            return (
                <div className="container" style={{fontSize: "12px"}}>
                <Nav/>
                {/* <a className="backHomeLink" href="http://www.lemonadebeats.com"><img className="logo" src={lemonadeLogo} alt="logo" /></a> */}
                <div className="shopHeader" style={styles}>
                    <h1 style={{fontSize:'64px', margin:0}}>THE STAND</h1>
                    <p style={{fontSize:'32px', color: 'black'}}>Fresh source for sounds. <br/>From the best producers around.</p>

                    {/* <TextField style={{width: '300px', background: 'white',}} id="standard-search" label="Search (under construction)" type="search" /> */}
                    <Autocomplete
                    id="combo-box-demo"
                    options={options}
                    onChange={handleInputSelect}
                    getOptionLabel={(option) => option}
                    disableClearable={false}
                    style={{ width: 300, marginBottom: '25px' }}
                    noOptionsText="Not Found"
                    renderInput={(params) => <TextField {...params} label="Search Artists" variant="outlined" />}
                    />
                    <Autocomplete
                    id="combo-box-demo2"
                    options={genreOptions}
                    onChange={handleGenreInputSelect}
                    getOptionLabel={(option) => option}
                    disableClearable={false}
                    style={{ width: 300 }}
                    noOptionsText="Not Found"
                    renderInput={(params) => <TextField {...params} label="Genre" variant="outlined" />}
                    />
                </div>
                <div style={{maxWidth: '850px', width: '100%', marginBottom: '100px'}}>
                    <div className="trackHeader">
                    <span>Track</span>
                    <span>BPM</span>
                    <span>Duration</span>
                    <span>Price</span>
                    </div>
                    {/* render the waveformTemps from state where they can be sorted as per Search Input  */}
                    {tracksToRender && tracksToRender.map((track, idx) => {
                             return <WaveFormTemp track={track} thisKey={idx} />
                    })}
                    {/* {renderTracks()} */}
                    {
                        filterChosen ? null : (
                            <div>
                                <Button onClick={handleLoadMore}>Load More..</Button>
                            </div>
                        )
                    }
                </div>
                </div>
            )
        }}
        </TracksContext.Consumer>
    )
}

export default Shop;