import React from 'react'
import lemonadeLogo from '../../assets/lbeats_logo.png';
// import lemonadeLogo from '../../assets/lemonadebeats_logo_large.webp';
import '../../App.css';
import PlayerHeader from '../PlayerHeader'
import { TracksContext } from '../../context/TracksContext';
import WaveForm from '../WaveForm';
import WaveFormTemp from '../WaveFormTemp';
import {Link} from 'react-router-dom'

const styles = {
    width: '100%',
    maxWidth: '850px',
    padding: '50px 25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    backgroundColor : '#FFE600',
    color: '#00D5FF',
}

const Profile = (props) => {
    const trackFromState = props.location.state.trackInfo
    return (
        <TracksContext.Consumer>
        {(value) => {
            const userTracks  = value.trackInfo && value.trackInfo.filter(track => track.metadata.userID === props.location.state.userID);
            const trackInfo = value.trackInfo && value.trackInfo;
            return (
                <div className="container" style={{fontSize: "12px"}}>
                <Link to='/'><img className="logo" src={lemonadeLogo} alt="logo" /></Link>
                {/* <a className="backHomeLink" href="http://www.lemonadebeats.com"><img className="logo" src={lemonadeLogo} alt="logo" /></a> */}
                <div className="shopHeader" style={styles}>
                    <div className="avatar">
                        <img style={{borderRadius: '50%'}} src={trackFromState.metadata.picture} alt=""/>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}} className="headerVertCopy">
                        <h1 style={{fontSize:'64px', margin:0}}>{`${trackFromState.metadata.nickname}'s collection`}</h1>
                        <p style={{fontSize:'32px', color: 'black', margin: '5px'}}>{userTracks && userTracks.length} Tracks</p>
                        <Link to="/shop">Continue Shopping</Link>
                    </div>
                </div>
               
                <div style={{maxWidth: '850px', width: '100%', marginBottom: '100px'}}>
                    <div className="trackHeader">
                    <span>Track</span>
                    <span>BPM</span>
                    <span>Duration</span>
                    <span>Price</span>
                    </div>
                    {/* render the waveformTemps from state where they can be sorted as per Search Input  */}
                    {userTracks && userTracks.map((track, idx) => {
                             return <WaveFormTemp track={track} thisKey={idx} />
                    })}
                    {/* {renderTracks()} */}
                   
                </div>

                </div>
            )
        }}
        </TracksContext.Consumer>
    )
}

export default Profile;