import React from 'react'
import lemonadeLogo from '../assets/lbeats_logo.png';
import homeLogo from '../assets/wedge.svg';
import {Link} from 'react-router-dom'

function Nav() {
    return (
        <div style={{display: 'flex', }}>
            {/* also need lemon wedge home button */}
            <a href='https://lemonadebeats.com'><img style={{width: '50%', maxWidth: '200px', marginTop: '40px', marginRight: '30px'}} src={homeLogo} alt="home-logo" />
            </a>
            {/* <Link to='/'><img style={{width: '100%', maxWidth: '200px', marginTop: '40px', marginRight: '30px'}} src={homeLogo} alt="home-logo" /></Link> */}
            <Link to='/'><img className="logo" src={lemonadeLogo} alt="app-logo" /></Link>
        </div>
    )
}

export default Nav
