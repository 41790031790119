import React from 'react'
import './Checkout.css'
// import StripeCheckout from 'react-stripe-checkout';
import {loadStripe} from '@stripe/stripe-js';
import {Elements, CardElement, useStripe, useElements} from '@stripe/react-stripe-js'
// import PlaceholderImage from '../../assets/placeholder.png'
import PlaceholderImage from '../../assets/wedge.svg'
import { Card } from '@material-ui/core';
import axios from 'axios'
import CheckoutSuccess from './CheckoutSuccess'

const CheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const {trackID, trackInfo} = props
    const trackMeta = trackInfo.metadata
    const cents = trackMeta.price * 100

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
        });

        if (!error) {
            const {id} = paymentMethod;

            try {
                const {data} = await axios.post("/api/charge", {id, amount: cents})
                // console.log(data);
                // axios.get(`http://localhost:3001/download/${trackInfo.filename}`);
                props.success();
            } catch (error) {
                console.log(error);
            }
        }
    }
    return (
        <form onSubmit={handleSubmit} style={{maxWidth: '400px', margin: '0 auto'}}>
            <img 
              src={PlaceholderImage}
              style={{width: "100%"}}
            />
            {trackInfo.metadata && (
            <div style={{fontSize: "20px",
                         color: "white",
                         fontWeight: "600",
                         padding: "10px 5px"}}>You are purchasing a download of <span className="trackInfo">{trackInfo.metadata.title}</span> by <span className="trackInfo">{trackInfo.metadata.artist}</span></div>
            )}
            <div style={{margin: "20px 0",
                        fontSize: "20px",
                        color: "white",
                        fontWeight: "600",
                        padding: "10px 5px"}}>Price: ${trackInfo.metadata.price}</div>
            <CardElement />
            <button id="pay" type="submit" disabled={!stripe}>
                Pay ${trackInfo.metadata.price}
            </button>
        </form>
    );
};

// dev test acct (disable)
// const stripePromise = loadStripe("pk_test_51HWPrVClSwBQHYbDZxuCnfVfFmvcl53qreOW0Rd7tY1YOFLckUeknQVUDpCW7RJJdVgjw39OQeRFrVyNHMtiM6Kq0064rOqpeP")
// other test acct
// const stripePromise = loadStripe("pk_test_51HelebEqMqYtANDHvQ6N8m31UNIj2CejLKGbp02HmZbzG0ReQH1AEvBrdLeD0P4nsNAkZn50yPKxRfHh5iNwOoLG00outaAUnL")
// REAL LIVE acct - DELETE
const stripePromise = loadStripe("pk_live_51HelebEqMqYtANDHonmawDS5iXpHA4KmzvGs8Ui0PhkGL6GWqkJjsV1JkBQaM1bOU4WSJXWe8QuiLCib4aKCKxWi00Rjp2tUQU")

const Checkout = (props) => {
  
      const [status, setStatus] = React.useState("ready");
  
      if (status === 'success') {

          return <CheckoutSuccess trackInfo={props.location.state.trackInfo}/>
      }
      return (
        <Elements stripe={stripePromise}>
          <CheckoutForm success={() => {setStatus("success")}} trackInfo={props.location.state.trackInfo} trackID={props.match.params.trackID} />
        </Elements>
      )
  }

  export default Checkout;

// export default class Checkout extends React.Component {
//   onToken = (token, addresses) => {
//     // TODO: Send the token information and any other
//     // relevant information to your payment process
//     // server, wait for the response, and update the UI
//     // accordingly. How this is done is up to you. Using
//     // XHR, fetch, or a GraphQL mutation is typical.
//     console.log(token)
    
//   };

//   render() {
//     const [status, setStatus] = React.useState("ready");

//     if (status === 'success') {
//         return (
//             <div>
//                 <div>Congrats on your fresh squeezed track purchase!</div>
//                 <a download href={`http://localhost:3001/files/${this.props.location.state.trackInfo.filename}`}></a>
//             </div>
//         )
//     }
//     return (
//       <Elements stripe={stripePromise}>
//         <CheckoutForm success={() => {setStatus("success")}} trackInfo={this.props.location.state.trackInfo} trackID={this.props.match.params.trackID} />
//       </Elements>
//     )
//   }
// }
