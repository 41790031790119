import styled from 'styled-components';

export const WaveformContianer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 48px;
  background: #fff;
  padding: 0;
  border-bottom: .5px solid #888;
  border-top: .5px solid #888;
`;

export const Wave = styled.div`
  width: 20%; 
  margin-left: 10px;
  margin-right: 10px;
`;

export const ProfileLink = styled.div`
  &:hover {
    text-decoration: underline;
  }
`;

export const Price = styled.div`
  background-color: #00D5FF;
  padding: 4px 9px;
  text-decoration: none;
  color: white;
  margin: 0 5px;
  width: 40px;
  font-size: 1.4em;
  flex: .5;
  display: flex;
  &:hover {
    background: yellow;
    color: blue;
  }
  & a {
    color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    &:hover {
      color: black;
    }
    @media(max-width : 500px) {
      font-size: 12px !important;
    }
  }
`

export const PlayButton = styled.button`
  width: 50px;
  height: 30px;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
  }

  & svg {
    height: 1.3em;
    width: 1.3em;
    fill: #00D5FF;
  }
`;

export const TrackName = styled.div`
  margin: 0 5px;
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .4px;
  @media(max-width : 500px) {
    font-size: 1em !important;
  }
`;
export const TrackArtist = styled.div`
  margin: 0 5px;
  font-size: 1em;
`;
export const TrackInfoContainer = styled.div`
  margin: 0 5px;
  width: 28%;
  overflow: hidden;
`;
export const TrackBPM = styled.div`
  margin: 0 5px;
  width: 40px;
  font-size: 1.4em;
  flex: .5;
`;
export const TrackDuration = styled.div`
  margin: 0 5px;
  width: 40px;
  font-size: 1.4em;
  flex: .5;
  @media(max-width : 500px) {
    font-size: 1em !important;
  }
`;