import React, { createContext, Component } from 'react';
// import API from "../utils/api";


export const TracksContext = createContext();

class TracksContextProvider extends Component {
  state = {
    setNewTrack: (newTrack) => {
        this.setState({});
    }
  }

  componentDidMount() {
    // Fetch does not send cookies. So you should add credentials: 'include'
    fetch("/api/files", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    })
      .then(response => {
        if (response.status === 200) return response.json();
        // if (response.status === 200) return response.text();
        throw new Error("Didn't GET any track information");
      })
      .then(res => {
        // TODO:: in this setState, let's do sorting and filtering needed for app and store in state
        this.setState({
          trackInfo: res
        });
      })
      .catch(error => console.log(error));
  };

    render() {
        return (
            <TracksContext.Provider value={{ ...this.state }}>
            {this.props.children}
            </TracksContext.Provider>
        )
    }
}

export default TracksContextProvider;