import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import dotenv from 'dotenv';
import App from './App';
import Shop from './components/shop/Shop';
import Checkout from './components/shop/Checkout';
import Profile from './components/profile/Profile';
import TracksContextProvider from './context/TracksContext';
import { Auth0Provider } from '@auth0/auth0-react';
import history from './history';
import { 
  BrowserRouter as Router,
  Switch,
  Route, } from "react-router-dom";

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

ReactDOM.render(
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}>
      <TracksContextProvider>
      <Router history={history}>
        <Switch>
        <Route path="/" exact component={App} />
        <Route path="/shop" component={Shop} />
        <Route path="/checkout/:trackID" component={Checkout} />
        <Route path="/producer-profile" component={Profile} />
        </Switch>
      </Router>
      </TracksContextProvider>
    </Auth0Provider>,
  document.getElementById('root')
);
