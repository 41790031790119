import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { TextField, Button, Select, MenuItem, FormControl } from '@material-ui/core/';
import Modal, { ModalProvider } from 'styled-react-modal'
// import './TitleModal.css';
import axios from 'axios'
import GenreDropdown from './GenreDropdown';
import history from '../history'


const StyledModal = Modal.styled`
  width: 20rem;
  height: 75%;
  height: auto;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  flex-direction: column;
  text-align: center;
  color: black;
`
class FancyModalButton extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: false,
      someInput: '',
      genre: "Hip-Hop",
      artist: props.track.metadata.artist,
      price: props.track.metadata.price,
      title: props.track.metadata.title,
    }

    this.toggleModal = this.toggleModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveEdits = this.saveEdits.bind(this);
    this.deleteTrack = this.deleteTrack.bind(this);
  }

  toggleModal (e) {
    // this.setState((prevState) => ({ isOpen: !prevState.isOpen }))
  }
  openModal (e) {
    this.setState({isOpen: true})
  }
  closeModal (e) {
    console.log('close modal')
    this.setState({isOpen: false})
  }

  handleInputChange = event => {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
        [name]: value
    });
  };

  handleGenreChange = (event) => {
    console.log('genre changed')
    const genre = event.target.value
    this.setState({genre: genre}, this.handleInputChange(event))
  }

  saveEdits (e) {
      e.preventDefault();
    const {title, artist, price, genre} = this.state
    const {track} = this.props
    // this.setState((prevState) => ({ isOpen: !prevState.isOpen }))
    axios.post(`/api/updateTrackMeta/${track.filename}`, {title, artist, price, genre})
        .then(data => console.log(data))
        .catch(err => console.log(err))
    console.log("saved edits")
    // reload to show changes
    // history.push('/')
    window.location.reload();
  }

  deleteTrack (e) {
    // this.setState((prevState) => ({ isOpen: !prevState.isOpen }))
    const { track } = this.props
    axios.post(`/api/deleteTrack/${track.filename}`)
    console.log("deleted track")
    // reload to show changes
    // history.push('/')
    window.location.reload();
  }

  render () {
    const {addATrackToContext, userID, track} = this.props;
    const genre = track.metadata.genre ? track.metadata.genre : this.state.genre
    const user = this.props.userID;
    return (
      <div onClick={this.openModal}>
        <span>EDIT</span>

        <StyledModal
          isOpen={this.state.isOpen}
          onEscapeKeydown={this.closeModal}>
          <h2 className="modalTitle">Edit Track Info</h2>
          {/* <span className="modalCopy">Add your track information and file below. After you submit, it may take a few minutes to process on our server..</span> */}
          <TextField defaultValue={track.metadata.title} onChange={this.handleInputChange} style={{marginBottom: '10px'}} name="title" label="Track Title" variant="outlined" />
          <TextField defaultValue={track.metadata.artist} onChange={this.handleInputChange} style={{marginBottom: '10px'}} name="artist" label="Artist" variant="outlined" />
          <TextField defaultValue={track.metadata.price} onChange={this.handleInputChange} style={{marginBottom: '10px'}} name="price" label="Price" variant="outlined" />
          <GenreDropdown genre={genre} handler={this.handleGenreChange}/>

          <Button style={{width: '100%', margin: '10px', background: '#00D5FF'}} onClick={this.saveEdits} variant="contained" color="primary">
            Save Edits
            </Button>
            <Button style={{width: '100%', margin: '10px', background: 'red'}} onClick={this.deleteTrack} variant="contained" color="secondary">
            Delete Track
            </Button>
            <span>Press ESC to exit</span>
        </StyledModal>
      </div>
    )
  }
}

export default class EditModal extends Component {
  render () {
    return (
      <ModalProvider>
        <span></span>
        <FancyModalButton userID={this.props.userID} track={this.props.track} />
      </ModalProvider>
    )
  }
}