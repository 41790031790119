import React, { Component } from 'react'
import { Range } from 'react-range';

class Ranger extends Component {
  state = { values: [50] };
  // calc a percent of song played
  // send it here through props
  // t
  render() {
    return (
      <Range
        style={{visibility: 'hidden'}}
        step={0.1}
        min={0}
        max={100}
        values={this.state.values}
        onChange={(values) => this.setState({ values })}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '6px',
              width: '20%',
              backgroundColor: '#ccc',
              visibility: 'hidden',
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '42px',
              width: '42px',
              backgroundColor: '#999'
            }}
          />
        )}
      />
    );
  }
}

export default Ranger;