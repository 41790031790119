import React from 'react'
// get select component from mui
import { Select, FormControl, MenuItem, InputLabel } from '@material-ui/core'

const genreOptions = ["Hip-Hop", ]

const GenreDropdown = (props) => {
    const { handler, genre } = props
    const [age, setAge] = React.useState('');
    const handleChange = (e) => {
        setAge(e.target.value)
    }
    return (
        <div>
            {/* add mui dropdown component with genreOptions - will need to handle the option select to fire api call */}
            <FormControl>
                <InputLabel id="demo-simple-select-label">Genre</InputLabel>
                <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={genre}
                onChange={handler}
                name="genre"
                defaultValue="Hip-Hop"
                >
                    <MenuItem value="None">]<em>None</em>]</MenuItem>
                    <MenuItem value="Hip-Hop">Hip-Hop</MenuItem>
                    <MenuItem value="Trap">Trap</MenuItem>
                    <MenuItem value="R&amp;B">R&amp;B</MenuItem>
                    <MenuItem value="New School">New School</MenuItem>
                    <MenuItem value="Rap">Rap</MenuItem>
                    <MenuItem value="Smooth">Smooth</MenuItem>
                    <MenuItem value="Club">Club</MenuItem>
                    <MenuItem value="New Wave">New Wave</MenuItem>
                    <MenuItem value="Pop">Pop</MenuItem>
                    <MenuItem value="Beats with Hooks">Beats with Hooks</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

export default GenreDropdown
